<template>
  <div class="inner-section">
      <card>
        <template v-slot:searchHeaderTitle>
          <h4 class="card-title">{{ formTitle() }}</h4>
        </template>
        <b-container fluid>
          <b-row>
              <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                <b-overlay :show="loading">
                  <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset">
                    <b-row>
                      <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <ValidationProvider name="Garden Name" vid="garden_id" rules="required|min_value:1">
                            <b-form-group
                              label-for="garden_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('teaGardenConfig.select_garden') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                :disabled="isGardenAdmin"
                                plain
                                v-model="formData.garden_id"
                                :options="masterTeaGardenGenInfoList"
                                id="garden_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <ValidationProvider name="" vid="clone_price" v-slot="{ errors }">
                          <b-form-group
                              label-for="clone_price">
                              <template v-slot:label>
                                {{ $t('teaGardenBtriService.schedule_id') }}
                              </template>
                              <b-form-input
                              id="beficiary_count"
                              v-model="formData.schedule_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              readonly
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <ValidationProvider name="" vid="clone_price" v-slot="{ errors }">
                          <b-form-group
                              label-for="clone_price">
                              <template v-slot:label>
                                {{ $t('globalTrans.fiscal_year') }}
                              </template>
                              <b-form-select
                                plain
                                id="fiscal_year"
                                :options="fiscalYearList"
                                v-model="formData.fiscal_year_id"
                                disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <ValidationProvider name="" vid="clone_price" v-slot="{ errors }">
                          <b-form-group
                              label-for="clone_price">
                              <template v-slot:label>
                                {{ $t('teaGardenBtriService.event_type') }}
                              </template>
                              <b-form-select
                            plain
                            id="fiscal_year"
                            :options="eventTypeList"
                            v-model="formData.event_type"
                            :state="errors[0] ? false : (valid ? true : null)"
                            disabled
                        >
                        <template v-slot:first>
                        <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <ValidationProvider name="" vid="clone_price" v-slot="{ errors }">
                          <b-form-group
                              label-for="clone_price">
                              <template v-slot:label>
                                {{ $t('teaGardenBtriService.event_name') }}
                              </template>
                              <b-form-input
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              id="beficiary_count"
                              v-model="formData.event_name"
                              :state="errors[0] ? false : (valid ? true : null)"
                              readonly
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <ValidationProvider name="" vid="clone_price" v-slot="{ errors }">
                          <b-form-group
                              label-for="clone_price">
                              <template v-slot:label>
                                {{ $t('teaGardenBtriService.place') }}
                              </template>
                              <b-form-input
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              id="beficiary_count"
                              v-model="formData.place_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                              readonly
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <body-card v-if="!nominationView">
                      <template>
                        <h5 class="card-title"> {{ $t('teaGardenBtriService.nominee') }} {{ $t('globalTrans.information') }}</h5>
                      </template>
                      <b-row>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                          <ValidationProvider name="" vid="participate_name_en" v-slot="{ errors }">
                            <b-form-group
                                label-for="participate_name_en">
                                <template v-slot:label>
                                  {{ $t('globalTrans.name') }}  {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="beficiary_count"
                                v-model="details.participate_name_en"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback d-block">
                                  {{ participate_name_en_error }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                          <ValidationProvider name="" vid="participate_name_bn" v-slot="{ errors }">
                            <b-form-group
                                label-for="participate_name_bn">
                                <template v-slot:label>
                                  {{ $t('globalTrans.name') }}  {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="beficiary_count"
                                v-model="details.participate_name_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback d-block">
                                  {{ participate_name_bn_error }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                          <ValidationProvider name="" vid="designation_en" v-slot="{ errors }">
                            <b-form-group
                                label-for="designation_en">
                                <template v-slot:label>
                                  {{ $t('globalTrans.designation') }}  {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="beficiary_count"
                                v-model="details.designation_en"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback d-block">
                                  {{ designation_en_error }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                          <ValidationProvider name="" vid="designation_bn" v-slot="{ errors }">
                            <b-form-group
                                label-for="designation_bn">
                                <template v-slot:label>
                                  {{ $t('globalTrans.designation') }}  {{ $t('globalTrans.bn') }}<span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="beficiary_count"
                                v-model="details.designation_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback d-block">
                                  {{ designation_bn_error }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                          <ValidationProvider name="Contact No" vid="contact_no" rules="required|min:11|max:11">
                            <b-form-group
                                slot-scope="{ valid, errors }"
                                label-for="contact_no">
                                <template v-slot:label>
                                  {{ $t('globalTrans.contact_no') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                type="tel"
                                id="contact_no"
                                v-model="details.contact_no"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                @keypress="isNumber"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback  d-block">
                                  {{ contact_no_error }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                          <ValidationProvider name="Email" vid="email" rules="required|email">
                            <b-form-group
                                slot-scope="{ valid, errors }"
                                label-for="email">
                                <template v-slot:label>
                                  {{ $t('globalTrans.email') }}
                                </template>
                                <b-form-input
                                type="email"
                                id="email"
                                v-model="details.email"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback  d-block">
                                  {{ email_error }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </body-card>
                    <b-row class="text-right" v-if="!nominationView">
                      <b-col>
                          <b-button @click="addItem()" type="button" variant="success" class="mr-2 mb-2 btn-sm">
                              {{ $t('globalTrans.add') }}
                          </b-button>
                      </b-col>
                    </b-row>
                    <b-row v-if="!nominationView">
                        <!-- -----------------Add More Start------------------- -->
                        <div class="table-wrapper table-responsive">
                          <table class="table table-striped table-hover table-bordered">
                            <b-thead>
                              <tr>
                                <!-- <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th> -->
                                <b-th class="text-center">{{ $t('globalTrans.name') }} {{ $t('globalTrans.en') }}</b-th>
                                <b-th class="text-center">{{ $t('globalTrans.name') }} {{ $t('globalTrans.bn') }}</b-th>
                                <b-th class="text-center">{{ $t('globalTrans.designation') }} {{ $t('globalTrans.en') }}</b-th>
                                <b-th class="text-center">{{ $t('globalTrans.designation') }} {{ $t('globalTrans.bn') }}</b-th>
                                <b-th class="text-center">{{ $t('globalTrans.contact_no') }}</b-th>
                                <b-th class="text-center">{{ $t('globalTrans.email') }}</b-th>
                                <b-th class="text-center">{{ $t('globalTrans.action') }} </b-th>
                              </tr>
                            </b-thead>
                            <b-tbody>
                              <template v-if="formData.details && formData.details.length">
                                  <b-tr v-for="(item, index) in formData.details" :key="index">
                                      <!-- <b-td class="text-center">{{ $n(index+1) }}</b-td> -->
                                      <b-td class="text-center">{{item.participate_name_en }}</b-td>
                                      <b-td class="text-center">{{item.participate_name_bn }}</b-td>
                                      <b-td class="text-center">{{item.designation_en }}</b-td>
                                      <b-td class="text-center">{{item.designation_bn }}</b-td>
                                      <b-td class="text-center">{{item.contact_no | mobileNumber }}</b-td>
                                      <b-td class="text-center">{{item.email }}</b-td>
                                      <td class="text-center">
                                        <b-button variant="action-btn delete mr-1" size="sm" :title="$t('globalTrans.delete')" @click="removeItem(index)" class="action-btn edit"><i class="ri-close-fill"></i></b-button>
                                      </td>
                                  </b-tr>
                              </template>
                              <template v-else>
                                <b-tr>
                                  <b-td colspan="7" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                </b-tr>
                              </template>
                            </b-tbody>
                          </table>
                        </div>
                        <!-- -----------------Add More End--------------------- -->
                        <b-col lg="6" sm="6">
                                        <ValidationProvider name="Remarks" vid="remarks" rules="">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="12"
                                              label-for="remarks"
                                              slot-scope="{ valid, errors }"
                                          >
                                            <template v-slot:label>
                                              {{ $t('globalTrans.remarks')}}
                                            </template>
                                            <b-form-textarea
                                                id="reason"
                                                v-model="formData.remarks"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-textarea>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                    </b-row>
                    <b-row class="text-right mb-3" v-if="!nominationView">
                        <b-col>
                          <!-- <b-button type="submit" @click="formData.status = 2" variant="warning" class="mr-2">{{$t('configuration.save_draft')}}</b-button> -->
                          <b-button type="submit" @click="formData.status = 1" variant="primary" class="mr-2">{{$t('globalTrans.save')}}</b-button>
                          <router-link :to="{ path: '/tea-garden-service/btri/nomination-list'}" class="btn btn-danger btn-sm">
                              {{ $t('globalTrans.cancel') }}
                          </router-link>
                        </b-col>
                    </b-row>
                    <b-row v-if="nominationView">
                        <!-- -----------------Add More Start------------------- -->
                        <div class="table-wrapper table-responsive">
                          <table class="table table-striped table-hover table-bordered">
                            <b-thead>
                              <tr>
                                <!-- <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th> -->
                                <b-th class="text-center">{{ $t('globalTrans.name') }} {{ $t('globalTrans.en') }}</b-th>
                                <b-th class="text-center">{{ $t('globalTrans.name') }} {{ $t('globalTrans.bn') }}</b-th>
                                <b-th class="text-center">{{ $t('globalTrans.designation') }} {{ $t('globalTrans.en') }}</b-th>
                                <b-th class="text-center">{{ $t('globalTrans.designation') }} {{ $t('globalTrans.bn') }}</b-th>
                                <b-th class="text-center">{{ $t('globalTrans.contact_no') }}</b-th>
                                <b-th class="text-center">{{ $t('globalTrans.email') }}</b-th>
                                <b-th class="text-center">{{ $t('globalTrans.action') }} </b-th>
                              </tr>
                            </b-thead>
                            <b-tbody>
                              <template v-if="formDataDetails && formDataDetails.length">
                                  <b-tr v-for="(item, index) in formDataDetails" :key="index">
                                      <!-- <b-td class="text-center">{{ $n(index+1) }}</b-td> -->
                                      <b-td class="text-center">{{item.participate_name_en }}</b-td>
                                      <b-td class="text-center">{{item.participate_name_bn }}</b-td>
                                      <b-td class="text-center">{{item.designation_en }}</b-td>
                                      <b-td class="text-center">{{item.designation_bn }}</b-td>
                                      <b-td class="text-center">{{item.contact_no | mobileNumber }}</b-td>
                                      <b-td class="text-center">{{item.email }}</b-td>
                                      <td class="text-center">
                                        <b-button variant="action-btn delete mr-1" size="sm" :title="$t('globalTrans.delete')" @click="removeItem(index)" class="action-btn edit"><i class="ri-close-fill"></i></b-button>
                                      </td>
                                  </b-tr>
                              </template>
                              <template v-else>
                                <b-tr>
                                  <b-td colspan="7" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                </b-tr>
                              </template>
                            </b-tbody>
                          </table>
                        </div>
                        <!-- -----------------Add More End--------------------- -->
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Remarks" vid="remarks" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="remarks"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('globalTrans.remarks')}}
                              </template>
                              <b-form-textarea
                                  id="reason"
                                  v-model="nominationRemarks"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>

                        <b-row class="text-right mb-3">
                        <b-col>
                          <b-row class="text-left mb-3">
                              <b-col>
                                <b-button @click="back" class="ml-3"><i class="ri-arrow-go-back-fill"></i>  {{ $t('teaGardenConfig.back') }}</b-button>
                              </b-col>
                          </b-row>
                        </b-col>
                    </b-row>
                    </b-row>
                  </b-form>
                </b-overlay>
              </ValidationObserver>
          </b-row>
        </b-container>
      </card>
  </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { nominationStore, getPrepareScheduleInfo } from '../../../api/routes'
import { helpers } from '@/utils/helper-functions'
import teaGardenService from '@/mixins/tea-garden-service'

export default {
  mixins: [teaGardenService],
  name: 'Form',
  props: ['id'],
data () {
  return {
    plantingMaterialList: [],
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      loading: false,
      formData: {
        garden_id: 0,
        schedule_main_id: 0,
        schedule_id: 0,
        fiscal_year_id: 0,
        event_type: 0,
        place: '',
        status: 0,
        remarks: '',
        details: []
      },
      details: {
        garden_id: 0,
        participate_name_en: '',
        participate_name_bn: '',
        designation_en: '',
        designation_bn: '',
        contact_no: '',
        email: ''
      },
      is_disable: false,
      isGardenAdmin: false,
      participate_name_en_error: '',
      participate_name_bn_error: '',
      designation_en_error: '',
      designation_bn_error: '',
      contact_no_error: '',
      email_error: '',
      editID: 0,
      nominationView: false,
      formDataDetails: [],
      nominationRemarks: ''
  }
},
created () {
  this.user_id = this.$store.state.Auth.authUser.user_id

  if (this.$route.params.id) {
    this.getScheduleData()
  } else {
     this.$router.push({ name: 'tea_garden_service.btri.nomination_list' })
  }
},
computed: {
  fiscalYearList () {
    return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
  },
  eventTypeList: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Training' : 'প্রশিক্ষণ' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Workshop' : 'কর্মশালা' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Course' : 'কোর্স' }
      ]
      return list
    },
  serviceList: function () {
    return this.$store.state.TeaGardenService.commonObj.masterServiceList.filter(item => [20, 21].includes(parseInt(item.value)))
  },
  valleyList: function () {
    return this.$store.state.TeaGardenService.commonObj.masterValleyList
  },
  madeTeaGradeList: function () {
    return this.$store.state.TeaGardenService.commonObj.masterMadeTeaGradeList
  },
  masterCloneNameList: function () {
    return this.$store.state.TeaGardenService.commonObj.masterCloneNameList
  },
  masterTeaGardenGenInfoList: function () {
    return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList
  }
},
watch: {
},
methods: {
  back () {
    this.$router.go(-1)
  },
  async getScheduleData () {
    this.loading = true
    this.nominationView = false
    this.$store.dispatch('mutateCommonProperties', { loading: true })
    let result = null
    const loadingState = { loading: false, listReload: false }
    result = await RestApi.getData(teaGardenServiceBaseUrl, `${getPrepareScheduleInfo}/${this.$route.params.id}`)
    loadingState.listReload = true
    this.$store.dispatch('mutateCommonProperties', loadingState)
    if (result.success) {
      this.formData = result.data
      this.formData.schedule_main_id = result.data.id
      this.formData.details = []
      if (this.isGardenAdminCheckGardenId()) {
        this.isGardenAdmin = true
        this.formData.garden_id = this.isGardenAdminCheckGardenId()
      }
      if (result.nomination && this.formData.garden_id) {
        this.nominationView = true
        this.formDataDetails = result.nomination
        this.nominationRemarks = result.nomination.remarks
      }
      this.getEventName(result.data)
    } else {
      this.$toast.success({
        title: this.$t('globalTrans.success'),
        message: 'Clone Price not Found!!!',
        color: '#D6E09B'
      })
    }
    this.loading = false
  },
  getEventName (data) {
      if (data.event_type === 1) {
        this.formData.event_name = this.$i18n.locale === 'en' ? data.training_name_en : data.training_name_bn
      } else if (data.event_type === 2) {
        this.formData.event_name = this.$i18n.locale === 'en' ? data.workshop_title_en : data.workshop_title_bn
      } else if (data.event_type === 3) {
        this.formData.event_name = this.$i18n.locale === 'en' ? data.course_name_en : data.course_name_bn
      }
    },
  async getOfficeUser (id) {
      const gardenData = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === parseInt(id))
      this.office_id = gardenData.office_id
      const params = {
        office_id: this.office_id
      }
      const result = await RestApi.getData(authServiceBaseUrl, '/user-management/user/office-user', params)
      if (result.success) {
        this.formData.user_id = result.data.value
        this.formData.applicant_name_en = result.data.text_en
        this.formData.applicant_name_bn = result.data.text_bn
      }
    },
  async addItem () {
    if (this.details.participate_name_en && this.details.participate_name_bn && this.details.designation_en && this.details.designation_bn && this.details.contact_no) {
      this.participate_name_en_error = ''
      this.participate_name_bn_error = ''
      this.designation_en_error = ''
      this.designation_bn_error = ''
      this.contact_no_error = ''
      this.email_error = ''
      var obj = {
        participate_name_en: this.details.participate_name_en,
        participate_name_bn: this.details.participate_name_bn,
        designation_en: this.details.designation_en,
        designation_bn: this.details.designation_bn,
        contact_no: this.details.contact_no,
        email: this.details.email,
        garden_id: this.formData.garden_id
      }
      const extItem = this.formData.details.find(eItm => eItm.participate_name_en === this.details.participate_name_en && eItm.contact_no === this.details.contact_no)
      if (extItem === undefined) {
        this.formData.details.push(obj)
      }
      this.details = {
        participate_name_en: '',
        participate_name_bn: '',
        designation_en: '',
        designation_bn: '',
        contact_no: '',
        email: ''
      }
      this.$refs.form.reset()
    } else {
      if (this.details.participate_name_en === '') {
        this.participate_name_en_error = 'Name (En) is not valid .'
      }
      if (!this.details.participate_name_bn) {
        this.participate_name_bn_error = 'Name (Bn) is not valid .'
      }
      if (!this.details.designation_en) {
        this.designation_en_error = 'Designation (En) is not valid .'
      }
      if (!this.details.designation_bn) {
        this.designation_bn_error = 'Designation (Bn) is not valid .'
      }
      if (!this.details.contact_no) {
        this.contact_no_error = 'Contact No is not valid .'
      }
      if (!this.details.email) {
        this.email_error = 'email No is not valid .'
      }
    }
  },
  removeItem (index) {
    this.formData.details.splice(index, 1)
    this.formData = Object.assign({}, this.formData)
  },
  async saveUpdate () {
    if (!this.formData.details.length) {
      return this.$toast.error({
        title: this.$t('globalTrans.error'),
        message: 'Please add at least one participant!!!'
      })
    }

    this.loading = true
    this.$store.dispatch('mutateCommonProperties', { loading: true })
    let result = null
    const loadingState = { loading: false, listReload: false }
    result = await RestApi.postData(teaGardenServiceBaseUrl, nominationStore, this.formData)
    loadingState.listReload = true
    this.$store.dispatch('mutateCommonProperties', loadingState)
    if (result.success) {
      this.$store.dispatch('LicenseRegistrationService/mutateCommonObj', { hasDropdownLoaded: false })
      this.$toast.success({
        title: this.$t('globalTrans.success'),
        message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
        color: '#D6E09B'
      })
      this.$router.push({ path: '/tea-garden-service/btri/nomination-list' })
    } else {
      this.$toast.error({
        title: this.$t('globalTrans.error'),
        message: result.message
      })
      this.$router.push({ path: '/tea-garden-service/btri/nomination-list' })
    }
    this.loading = false
  },
  getPantMaterials (id) {
    return this.$store.state.TeaGardenService.commonObj.plantingMaterialList.filter(item => item.status === 1 && item.service_id === id)
  },
  payableAmountCalculate () {
    this.qty_error = ''
    if (this.user_id === 1) {
      if ((parseInt(this.details.total_qty) > this.details.stock)) {
        const actualQty = this.details.stock
        this.$nextTick(() => {
          this.details.total_qty = parseInt(actualQty)
        })
      }
    }

    this.details.total_price = 0
    const clonePrice = this.details.clone_price
    this.details.total_price = parseInt(clonePrice) * parseInt(this.details.total_qty)
  },
  getMatName (id) {
    const data = this.plantingMaterialList.find(item => item.value === id)
    if (data) {
      return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
    }
  },
  getCloneName (id) {
    const data = this.masterCloneNameList.find(item => item.value === id)
    if (data) {
      return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
    }
  },
  formTitle () {
      return (!this.$route.query.id) ? this.$t('teaGardenBtriService.nomination_submit') + ' ' + this.$t('globalTrans.entry') : this.$t('teaGardenBtriService.nomination_submit') + ' ' + this.$t('globalTrans.modify')
  },
  getTotalQty () {
      let totalQty = 0
      this.formData.details.forEach(item => {
        totalQty += parseFloat(item.qty)
      })
      return totalQty
  },
  isNumber (event) {
      helpers.isNumber(event)
  },
  approvalCalculation (index) {
    const obj = this.formData.details[index]

    if ((parseInt(obj.qty) > obj.clone_quantity.stock_quantity)) {
      const actualQty = obj.clone_quantity.stock_quantity
      this.$nextTick(() => {
        obj.qty = parseInt(actualQty)
        obj.total_price = parseInt(obj.price) * obj.qty
      })
    } else {
      obj.total_price = parseInt(obj.price) * parseInt(obj.qty)
    }
  }
  }
}
</script>
